import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import imgWorkCover from "../assets/image/jpg/details/details-2.jpg";
import imgS1 from "../assets/image/jpg/details/details-12.jpg";
import imgS2 from "../assets/image/jpg/details/details-11.jpg";
import imgS3 from "../assets/image/jpg/details/details-10.jpg";
import imgS4 from "../assets/image/jpg/details/details-9.jpg";
import imgS5 from "../assets/image/jpg/details/details-14.jpg";
import imgS6 from "../assets/image/jpg/details/details-13.jpg";
import { device } from "../utils"
import srxpCover from "../assets/image/srxp-cover.png";
import List from "../components/Core/List";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">FINTECH</Text>
                  <Title className="my-4 text-center">
                    Exact - SRXP
                    <br/>
                    Expense Management System <br />
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    <br />
                    An online Expense Management System serving over 3000 customers. The system is used by companies to manage their expenses. Mainly bigger customers that
                    have a lot of employees. The system is used by the employees to submit their expenses and by the finance department to approve and pay them.
                    <br />
                    <br />
                    Due to the competitive market that SRXP was doing business in, it depended heaviliy on building new features such that cilents could be convinced to come aboard
                    but also stay aboard. This is one of the reasons I loved working here, building new features and getting them out for release as soon as possible.
                    <br />
                    <br />
                    In my latest years at SRXP I became CTO working with a team of 6 developers. I was responsible for building, maintaining
                    and architecting the API and portal. Servers (DevOps) was also under my control. I am very fortunate to have gained massive amount of experiences
                    in the role I've had here.
                    <br />
                    <br />
                    I've worked on numerous big features here, ranging from an elaborate insights tool (web based) for business analysts to
                    gain insight into their expenses. I've build direct to banks in order to get financial transactions into SRXP automatically and into the account
                    of a user of SRXP. I've did big overhauls on the software to make it more flexible and future-proof.
                  </Text>
                </Box>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text className="pt-4 pb-2" css={`font-weight: 800`} variant="h4">
                    Experiences gained here:
                  </Text>

                  <List>
                    <li>EmberCLI</li>
                    <li>CakePHP</li>
                    <li>AWS Web Services - such as EB, Ec2, S3, VPCs, RDS</li>
                    <li>Docker</li>
                    <li>Working as a CTO and leading a team.</li>
                  </List>

                  <Text variant="h5">
                    Any further questions? Feel free to contact me!
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>

        <div className="mt-lg-1">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time Active</Text>
                <Title variant="cardBig" className="mt-3">
                  6 Years
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">From - Until</Text>
                <Title variant="cardBig" className="mt-3">
                  April 2015 - June 2021
                </Title>
              </Col>
              <Col lg="4" className="d-flex justify-content-lg-end">
                <a target="_blank" href="https://srxp.com">
                  <Button arrowRight>Website</Button>
                </a>
              </Col>
            </Row>

            <Box>
              <img src={srxpCover} alt="" className="img-fluid w-100" />
            </Box>
          </Container>
        </div>
        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
